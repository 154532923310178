import React from 'react';
import './index.css'
import { gsap, Expo } from "gsap";
import { useEffect } from 'react';

const App = () => {

  useEffect(() => {
    const timeline = gsap.timeline({ Default: { Easing: Expo.easeOut } });

    timeline.from('.logo', { opacity: 0, duration: 1.5, ease: 'sine.out', scale: 0.1 })
      .from('#content', { opacity: 0, duration: 0.8, ease: 'expo.out', scale: 0.9 })
      .from('.contact', { opacity: 0, duration: 2, ease: 'expo.out', scale: 1 })
  })

  return (
    <main className='container'>
      <div id='box' className='box'>
        <div>
          <img src={process.env.PUBLIC_URL + 'images/logo.svg'} className='logo' alt='Tidya logo' />
        </div>
        <div id='content'>
          <div className='slogan'>
            Seniorkompetanse på  digitalisering
          </div>
          <div className='services'>
            Konsulenttjenester innen systemutvikling, systemarkitektur og teamledelse.
          </div>
        </div>
        <div className='contact'>
          <a href='https://www.linkedin.com/company/tidya' alt='Ta kontakt' className='contact-button'>Ta kontakt</a>
        </div>
      </div>
    </main>
  );
}

export default App;
